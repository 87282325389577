<template> 

  <header>
    <CabecarioPrincipal title="Consultas Diversas - Portal de Transparêcia de RR - Web Sevices" />
  </header> 


  <div class="home">
    <video class= "video-container" oncontextmenu="return false;" autoplay muted disablePictureInPicture controlsList="nodownload" width=100% height=100%>
			<source src="@/assets/cge.webm" type="video/webm">
		</video>	
    <div  class="div-botoes">
      <button class="botoes-opc"  @click="_orcnatureza">Execução Orçamentaria da<br>Despesa por Natureza</button>
      <br><br>
      <button class="botoes-opc"  @click="_qdd">Quadro de Detalhamento de<br>Despesa Mensal - QDD</button>
      <br><br>
      <button class="botoes-opc"  @click="_grareccateco">Gráfico da Receita Arrecadada<br>por Categoria Econômica</button>
      <br><br>
      <button class="botoes-opc"   @click="_ppa">Consulta a Consolidação do<br>Programa do PPA</button>
      <br><br>
      <button class="botoes-opc"  @click="_orcsubelemento">Execução Orçamentária<br>por Subelemento</button>      
    </div>
  </div>
  <footer>
    <RodapePrincipal />
  </footer>



</template>

<script>
import CabecarioPrincipal from '@/components/CabecarioPrincipal.vue';
import RodapePrincipal from '@/components/RodapePrincipal.vue';
export default {
  name: 'HomeView',
  components: {
    CabecarioPrincipal,
    RodapePrincipal
  },
  methods: {
    _qdd() {
      this.$router.push({ name: 'qdd' });
    },
    _grareccateco() {
      this.$router.push({ name: 'grareccateco' });
    },
    _orcnatureza() {
      this.$router.push({ name: 'orcnatureza' });
    },    
    _ppa() {
      this.$router.push({ name: 'ppa' });
    },    
    _orcsubelemento() {
      this.$router.push({ name: 'orcsubelemento' });
    },    

  }

}
</script>

<style scoped>

.home {
  position: relative;
  top: 5px;
}

.div-botoes {
  position: absolute;
  top: 40px;
  left:150px;
}

.video-container { border-radius: 10px;}

.botoes-opc {
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #1a488d;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px; 
  width: 280px;
}

.botoes-opc:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

</style>

