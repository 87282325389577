<template>
          <header>
            <CabecarioPrincipal title="Consulta a Consolidação do Programa do PPA"  subTitle="Fonte: Base de dados do FIPLAN/RR"/>
          </header> 
          <main>
            <br>
            <div class="form-entrada">
              <form @submit.prevent="buscaDados" >
           
                <div>
                  <label for="ano_exe">Exercício/Ano</label>
                  <select style="width: 130px;" v-model="ano_exe" id="comboAnos" required>
                    <option v-for="opcao in lista_anos"  :key="opcao.value" :value="opcao.value"> 
                      {{opcao.value}}
                    </option>
                  </select>
                </div>  

                <div>
                 <label for="ppa">Tipo relatório</label>
                 <select style="width: 150px;" v-model="ppa" id="comboPpa" required>
                    <option value="1">PPA Original</option>
                    <option value="2">PPA Revisão</option>
                 </select>
               </div>  

               <div>
                  <label for="uni_orc">Unidade Orçamentaria</label>
                  <select style="width: 430px;" v-model="uni_orc" id="comboUniOrc" required>
                    <option  style="text-align: left;" v-for="opcao in lista_uo"  :key="opcao.codigoUnidadeorcamentaria"  :value="opcao.codigoUnidadeorcamentaria"> 
                      {{opcao.descricaoUnidadeOrcamentaria}} / {{opcao.sigla}} /  {{opcao.codigoUnidadeorcamentaria}}
                    </option>
                  </select>
                </div>  
                <div>
                  <button type="submit">Consulta</button>
                </div>  
              </form>
              <br>
              <LoadDados v-if="mostraLoad"/>
              <p></p>
              <div v-if="responseData">
                <iframe :src="pdfData" width="100%" height="1000px"></iframe>
              </div>
              <br>
            </div>
          </main>  
          <footer>
            <RodapePrincipal />
          </footer>
        </template>
          
        
        
        <script>
        import axios from 'axios';
        import { XMLParser } from 'fast-xml-parser';
        import Swal from 'sweetalert2';
        import CabecarioPrincipal from '@/components/CabecarioPrincipal.vue';
        import RodapePrincipal from '@/components/RodapePrincipal.vue';
        import LoadDados from "@/components/LoadDados.vue";
        
        export default {
          components: {
            CabecarioPrincipal,
            RodapePrincipal,
            LoadDados
            
          },
          data() {
            return {
              ano_exe: '',
              uni_orc: '',
              tip_rel: '1',
              responseData: null,
              pdfData: "",
              mostraLoad: false,
              lista_uo: [],
              lista_anos: [],
              ppa: ''
            };
          },
          mounted() {
            this.lista_anos = JSON.parse(localStorage.getItem("p_lis_ano"));    
            this.lista_uo = JSON.parse(localStorage.getItem("p_lis_uni_orc"));
          },
          methods: {
            async buscaDados() {
              this.mostraLoad = true;
              this.responseData = null;
        
              const dados_api = {
                  soapEnvelope: `
                    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:lc1="LC131_2009PPA">
                    <soapenv:Header/>
                    <soapenv:Body>
                    <lc1:gerarRelatorio>
                    <lc1:exercicio>`+this.ano_exe+`</lc1:exercicio>
                    <lc1:unidadeOrcamentaria>`+this.uni_orc+`</lc1:unidadeOrcamentaria>
                    <lc1:tipoPPA>`+this.ppa+`</lc1:tipoPPA>
                    <lc1:tipoRelatorio>1</lc1:tipoRelatorio>
                    </lc1:gerarRelatorio>
                    </soapenv:Body>
                    </soapenv:Envelope>`,
                  url_ws: 'https://fiplan.rr.gov.br//services/LC131_2009_PPA'
              };
              try {
                const  { data }  = await axios.post("https://ws-api.host-server.dev.br/soap",dados_api);
                const parser = new XMLParser();
                const jsonResponse = parser.parse(data);
                this.responseData = jsonResponse["soap:Envelope"]["soap:Body"]["ns1:gerarRelatorioResponse"]["ns1:out"];
                const binaryString = this.responseData;
                this.mostraLoad = false;
                console.log(typeof this.responseData);
                this.pdfData = `data:application/pdf;base64,${binaryString}`;
              } catch (error) {
                  Swal.fire({confirmButtonColor: '#3085d6',title: 'Desculpa',
                    html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Houve um erro ao acessar o serviço da API </p> <p>Pode ser sua internet, tente novamente</p> <p>'+error.message+'</p>',
                    icon: '',confirmButtonText: 'Ok'});
                  this.mostraLoad = false;
              }
            }
          }
        };
        </script>
        
        <style scoped>
        main {  
          flex: 1; /* Faz o main ocupar o espaço restante disponível */  
          min-height: 70vh;
        }
        form {
          display: flex; /* Alinha horizontalmente */
          max-width: 820px; /* largura do form */
          margin: 0 auto;  /* centraliza */
          padding: 20px;
          border: 2px solid #ddd;
          border-radius: 8px;
        }
        
        div {
          margin-bottom: 1px;  /* margim de baixo div */
          margin-right: 10px;
        }
        
        label {
          display: block;
          margin-bottom: 5px;  /* margem de baixo */
          font-weight: bold;
          color: #1a488d;
        }
        
        input {
          width: 100%;
          padding: 8px;
          margin-bottom: 10px;
          border-radius: 4px;
          border: 1px solid #ccc;
          border-radius: 8px;
        }
        
        select {
          border-radius: 4px;
          height: 35px;
          font-size: 16px;
          text-align: center
        }
        
        button {
          padding: 10px 15px;
          background-color: #3085d6;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          height: 40px;
          margin-top: 20px;
          margin-left: 10px;
        }
        
        button:hover {
          background-color: #1a488d;
        }
        
        h2, h3 {
          text-align: center;
        }
        </style>