<template>
    <header>
      <CabecarioPrincipal title="Gráfico da Receita Arrecadada por Categoria Ecônomica"   subTitle="Fonte: Base de dados do FIPLAN/RR"/>
    </header> 
    <main>
<!--      {{ tmp }}   -->
      <br>
      <div class="form-entrada">
        <form @submit.prevent="buscaDados" >

          <div>
            <label for="ano_exe">Exercício</label>
            <select style="width: 100px; height: 32px;" v-model="ano_exe" id="comboAnos" required>
              <option v-for="opcao in lista_anos"  :key="opcao.value" :value="opcao.value"> 
                {{opcao.value}}
              </option>
            </select>
          </div>            

          <div>
            <label for="periocidade">Periocidade</label>
            <select style="width: 120px; height: 32px;" v-model="periocidade" id="comboPeriocidade" required>
              <option value="6">Anual</option>
              <option value="5">Semestral</option>
              <option value="4">Trimestral</option>              
              <option value="3">Bimestral</option>
              <option value="2">Mensal</option>
            </select>
          </div>  

          <div v-if="periocidade === '5'">
            <label for="semestre">Semestre</label>
            <select style="width: 100px; height: 32px;" v-model="semestre" id="comboSemestre" required>
              <option value="1">Primeiro</option>
              <option value="2">Segundo</option>
            </select>
          </div>  

          <div v-if="periocidade === '4'">
            <label for="trimestre">Trimestre</label>
            <select style="width: 100px; height: 32px;" v-model="trimestre" id="comboTrimestre" required>
              <option value="1">Primeiro</option>
              <option value="2">Segundo</option>
              <option value="3">Terceiro</option>
              <option value="4">Quarto</option>
            </select>
          </div>  

          <div v-if="periocidade === '3'">
            <label for="bimestre">Bimestre</label>
            <select style="width: 100px; height: 32px;" v-model="bimestre" id="comboBimestre" required>
              <option value="1">Primeiro</option>
              <option value="2">Segundo</option>
              <option value="3">Terceiro</option>
              <option value="4">Quarto</option>
              <option value="5">Quinto</option>
              <option value="6">Sexto</option>
            </select>
          </div>  

          <div v-if="periocidade === '2'">
            <label for="mes_exe">Mês</label>
            <select style="width: 130px; height: 32px;" v-model="mes_exe" id="comboMeses" required>
              <option v-for="opcao in lista_meses"  :key="opcao.value" :value="opcao.value"> 
                {{opcao.text}}
              </option>
            </select>
          </div>  


          <div>
            <label for="consolidado_sn">Consolidado</label>
            <select style="width: 100px; height: 32px;" v-model="consolidado_sn" id="comboConsolidado" required>
              <option value="1">Sim</option>
              <option value="2">Não</option>
            </select>
          </div>  


          <div v-if="consolidado_sn === '1'">
            <label for="poder">Tipo de poder</label>
            <select style="width: 150px; height: 32px;" v-model="poder" id="comboPoder" required>
              <option value="1">1 - Executivo</option>
              <option value="2">2 - Legislativo</option>
              <option value="3">3 - Judiciário</option>
            </select>
          </div>  

          <div  v-if="consolidado_sn === '2'">
            <label for="uni_orc">Unidade Orçamentaria</label>
            <select style="width: 430px; height: 32px;" v-model="uni_orc" id="comboUniOrc" required>
              <option style="text-align: left;" v-for="opcao in lista_uo"  :key="opcao.codigoUnidadeorcamentaria"  :value="opcao.codigoUnidadeorcamentaria"> 
                {{opcao.descricaoUnidadeOrcamentaria}} / {{opcao.sigla}} /  {{opcao.codigoUnidadeorcamentaria}}
              </option>
            </select>
          </div>  
          <div>
            <button type="submit">Consulta</button>
          </div>  
        </form>
        <br>
        <LoadDados v-if="mostraLoad"/>
        <p></p>
        <div v-if="responseData">
          <iframe :src="pdfData" width="100%" height="1000px"></iframe>
        </div>
        <br>
      </div>
    </main>  
    <footer>
      <RodapePrincipal />
    </footer>
  </template>
    
  
  
  <script>
  import axios from 'axios';
  import { XMLParser } from 'fast-xml-parser';
  import Swal from 'sweetalert2';
  import CabecarioPrincipal from '@/components/CabecarioPrincipal.vue';
  import RodapePrincipal from '@/components/RodapePrincipal.vue';
  import LoadDados from "@/components/LoadDados.vue";
  
  export default {
    components: {
      CabecarioPrincipal,
      RodapePrincipal,
      LoadDados
      
    },
    data() {
      return {
        tmp: "",
        ano_exe: '',
        mes_exe: '',
        uni_orc: '',
        periocidade: "",
        tip_rel: '1',
        responseData: null,
        pdfData: "",
        mostraLoad: false,
        lista_meses: [],
        lista_uo: [],
        lista_anos: [],
        consolidado_sn: '',
        semestre: '',
        trimestre: '',
        bimestre: '',
        periodo: '',
        orgao: ''
      };
    },
    mounted() {
      this.lista_meses = JSON.parse(localStorage.getItem("p_lis_mes"));
      this.lista_anos = JSON.parse(localStorage.getItem("p_lis_ano"));    
      this.lista_uo = JSON.parse(localStorage.getItem("p_lis_uni_orc"));
    },
    methods: {
      async buscaDados() {
        this.mostraLoad = true;
        this.responseData = null;
        switch (this.periocidade) {
          case '2': this.periodo = this.mes_exe; break;
          case '3': this.periodo = this.bimestre; break;
          case '4': this.periodo = this.trimestre; break;
          case '5': this.periodo = this.semestre; break;
          case '6': this.periodo = '0'; break;}

        switch (this.consolidado_sn) {
          case '1': this.uni_orc = ""; break;
          case '2': this.poder = ''; break;}

        const dados_api = {
          soapEnvelope: `
            <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:lc1="LC131_2009_Grafico_Receita_Arrecadada_Categoria_Economica">
              <soapenv:Header/>
              <soapenv:Body>
                <lc1:gerarRelatorioFIP736>
                  <lc1:exercicio>`+this.ano_exe+`</lc1:exercicio>
                  <lc1:cdUO>`+this.uni_orc+`</lc1:cdUO>
                  <lc1:periodicidade>`+this.periocidade+`</lc1:periodicidade>
                  <lc1:periodo>`+this.periodo+`</lc1:periodo>
                  <lc1:consolidado>`+this.consolidado_sn+`</lc1:consolidado>
                  <lc1:cdOrgao></lc1:cdOrgao>
                  <lc1:tpPoder>`+this.poder+`</lc1:tpPoder>
                  <lc1:tipoRelatorio>1</lc1:tipoRelatorio>
                </lc1:gerarRelatorioFIP736>
              </soapenv:Body>
            </soapenv:Envelope>
            `,
           url_ws: "https://fiplan.rr.gov.br/services/LC131_2009_Grafico_Receita_Arrecadada_Categoria_Economica"
        };
        try {
          const  { data }  = await axios.post("https://ws-api.host-server.dev.br/soap",dados_api);
          const parser = new XMLParser();
          const jsonResponse = parser.parse(data);
          this.tmp = jsonResponse;
          this.responseData = jsonResponse["soap:Envelope"]["soap:Body"]["ns1:gerarRelatorioFIP736Response"]["ns1:out"];
          const binaryString = this.responseData;
          this.mostraLoad = false;
          this.pdfData = `data:application/pdf;base64,${binaryString}`;
        } catch (error) {
            Swal.fire({confirmButtonColor: '#3085d6',title: 'Desculpa',
              html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Houve um erro ao acessar o serviço da API</p> <p>Pode ser sua internet, tente novamente</p> <p>'+error.message+'</p>',
              icon: '',confirmButtonText: 'Ok'});
            this.mostraLoad = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  main {  
    flex: 1; /* Faz o main ocupar o espaço restante disponível */  
    min-height: 70vh;
  }
  form {
    display: flex; /* Alinha horizontalmente */
    max-width: 1000px; /* largura do form */
    margin: 0 auto;  /* centraliza */
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 8px;
  }
  
  div {
    margin-bottom: 1px;  /* margim de baixo de ima div */
    margin-right: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;  /* margem de baixo */
    font-weight: bold;
    color: rgb(3, 3, 206);
  }
  
  select {
    border-radius: 4px;
    height: 35px;
    font-size: 16px;
    text-align: center
  }
  
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 15px;
    background-color: #3085d6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    margin-top: 17px;
    margin-left: 10px;
  }
  
  button:hover {
    background-color: #1a488d;
  }
  
  h2, h3 {
    text-align: center;
  }
  </style>