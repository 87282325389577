<template>
 <div class="cabecario">
      <div>
        <img src="@/assets/logo.png" alt="Logo" class="header-logo" />
      </div>
      <div> 
        <h1 class="header-title">{{ title }}</h1>
        <h5> {{subTitle}}</h5>
     </div>
  </div> 
</template>
  
  <script>
  export default {
    props: {
      title: {type: String},
      subTitle: {type: String}
    }
  };
  </script>
  
  <style scoped>
  .cabecario {
    display: flex;
    justify-content: center;
    align-items: center;
/*    background-color: #cacaca; */
    background-image: linear-gradient(to left, #abd4f1, #4d9ad5 18%, #67a6dd 45%, #9dc4f1 77%, #c6daf3);
/*    border: 4px solid #acacac;  */
    padding: 10px;
    width: 99%;
    height: 80px;
    border-radius: 10px;}
  
  .header-logo {
    width: 350px;
    height: 70px;
    margin-right: 35px;}
  
  .header-title {
    font-size: 24px;
    color: #333;}
  </style>
  