<template>
  <header>
    <CabecarioPrincipal title="Consulta Execução Orçamentaria da Despesa por Natureza"  subTitle="Fonte: Base de dados do FIPLAN/RR"/>
  </header> 
  <main>
    <br>
    <div class="form-entrada">
      <form @submit.prevent="buscaDados" >
   
        <div>
          <label for="ano_exe">Exercício/Ano</label>
          <select style="width: 130px;" v-model="ano_exe" id="comboAnos" required>
            <option v-for="opcao in lista_anos"  :key="opcao.value" :value="opcao.value"> 
              {{opcao.value}}
            </option>
          </select>
        </div>  
        <div>
          <label for="mes_exe">Período/Mês</label>
          <select style="width: 130px;" v-model="mes_exe" id="comboMeses" required>
            <option v-for="opcao in lista_meses"  :key="opcao.value" :value="opcao.value"> 
              {{opcao.text}}
            </option>
          </select>
        </div>  
        <div>
          <label for="uni_orc">Unidade Orçamentaria</label>
          <select style="width: 430px;" v-model="uni_orc" id="comboUniOrc" required>
            <option  style="text-align: left;" v-for="opcao in lista_uo"  :key="opcao.codigoUnidadeorcamentaria"  :value="opcao.codigoUnidadeorcamentaria"> 
              {{opcao.descricaoUnidadeOrcamentaria}} / {{opcao.sigla}} /  {{opcao.codigoUnidadeorcamentaria}}
            </option>
          </select>
        </div>  
        <div>
          <button type="submit">Consulta</button>
        </div>  
      </form>
      <br>
      <LoadDados v-if="mostraLoad"/>
      <p></p>
      <div v-if="responseData">
        <iframe :src="pdfData" width="100%" height="1000px"></iframe>
      </div>
      <br>
    </div>
  </main>  
  <footer>
    <RodapePrincipal />
  </footer>
</template>
  


<script>
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import Swal from 'sweetalert2';
import CabecarioPrincipal from '@/components/CabecarioPrincipal.vue';
import RodapePrincipal from '@/components/RodapePrincipal.vue';
import LoadDados from "@/components/LoadDados.vue";

export default {
  components: {
    CabecarioPrincipal,
    RodapePrincipal,
    LoadDados
    
  },
  data() {
    return {
      ano_exe: '',
      mes_exe: '',
      uni_orc: '',
      tip_rel: '1',
      responseData: null,
      pdfData: "",
      mostraLoad: false,
      lista_meses: [],
      lista_uo: [],
      lista_anos: []
    };
  },
  mounted() {
    this.lista_meses = JSON.parse(localStorage.getItem("p_lis_mes"));
    this.lista_anos = JSON.parse(localStorage.getItem("p_lis_ano"));    
    this.lista_uo = JSON.parse(localStorage.getItem("p_lis_uni_orc"));
  },
  methods: {
    async buscaDados() {
      this.mostraLoad = true;
      this.responseData = null;

      const dados_api = {
          soapEnvelope: `
              <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:lc1="LC131_2009ExecucaoOrcamentariaNAtureza">
              <soapenv:Header/>
              <soapenv:Body>
              <lc1:gerarRelatorioFIP617>
              <lc1:exercicio>`+this.ano_exe+`</lc1:exercicio>
              <lc1:cdUnidadeOrcamentaria>`+this.uni_orc+`</lc1:cdUnidadeOrcamentaria>
              <lc1:mes>`+this.mes_exe+`</lc1:mes>
              <lc1:tipoRelatorio>1</lc1:tipoRelatorio>
              </lc1:gerarRelatorioFIP617>
              </soapenv:Body>
              </soapenv:Envelope>`,
          url_ws: 'https://fiplan.rr.gov.br//services/LC131_2009_ExecucaoOrcamentariaNatureza'
      };
      try {
        const  { data }  = await axios.post("https://ws-api.host-server.dev.br/soap",dados_api);
        const parser = new XMLParser();
        const jsonResponse = parser.parse(data);
        this.responseData = jsonResponse["soap:Envelope"]["soap:Body"]["ns1:gerarRelatorioFIP617Response"]["ns1:out"];
        const binaryString = this.responseData;
        this.mostraLoad = false;
        console.log(typeof this.responseData);
        this.pdfData = `data:application/pdf;base64,${binaryString}`;
      } catch (error) {
          Swal.fire({confirmButtonColor: '#3085d6',title: 'Desculpa',
            html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Houve um erro ao acessar o serviço da API </p> <p>Pode ser sua internet, tente novamente</p> <p>'+error.message+'</p>',
            icon: '',confirmButtonText: 'Ok'});
          this.mostraLoad = false;
      }
    }
  }
};
</script>

<style scoped>
main {  
  flex: 1; /* Faz o main ocupar o espaço restante disponível */  
  min-height: 70vh;
}
form {
  display: flex; /* Alinha horizontalmente */
  max-width: 820px; /* largura do form */
  margin: 0 auto;  /* centraliza */
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
}

div {
  margin-bottom: 1px;  /* margim de baixo div */
  margin-right: 10px;
}

label {
  display: block;
  margin-bottom: 5px;  /* margem de baixo */
  font-weight: bold;
  color: #1a488d;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

select {
  border-radius: 4px;
  height: 35px;
  font-size: 16px;
  text-align: center
}

button {
  padding: 10px 15px;
  background-color: #3085d6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  margin-top: 20px;
  margin-left: 10px;
}

button:hover {
  background-color: #1a488d;
}

h2, h3 {
  text-align: center;
}
</style>