<template>
    <p></p>
    <div class="div-load">
        <img src="@/assets/esquema.gif" alt="GIF animado" height="120px">
        <div>
            <h2 v-if="showTitle">Aguarde so um momento</h2>
            <h2 v-if="showTitle">Buscando os dados...</h2>
            <h2 v-if="showTitle2">Só mais um pouco</h2>
            <h2 v-if="showTitle2" style="color: red;">Ainda processando dados</h2>

        </div>    
        <img src="@/assets/aruanatransp.gif" alt="GIF animado" height="120px">
    </div>
</template>

<script>
    export default { 
        data() {return {showTitle: true, showTitle2: false};},
        mounted() {setTimeout(() => {this.showTitle = false; this.showTitle2 = true;}, 8000)}
    };
</script>


<style scoped>
    .div-load {
        border: 5px solid #ddd;
        display: flex;  /*  alinha horizontal os objetos */
        width: 500px;
        border-radius: 10px; /* Aqui você define o arredondamento */    
        margin: 0 auto;  /* centraliza */
    }
</style>
