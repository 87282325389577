<template>
  <!-- 
  <h1>Usado para testes {{ tmp }}</h1>    
  <h1>em todas as janelas </h1>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/qdd">QDD</router-link>    
  </nav>
  -->
  <router-view/>
 
</template>



<script>
import axios from 'axios';
// import Swal from 'sweetalert2';

export default {
  data() {
    return {
      token: null,
      username_api: "integracao_geral",
      password_api: "agtTAG57732#@#",
      tmp: null  // USADO PARA TESTES
    };
  },
  mounted() {
    localStorage.setItem("p_url_api_token","https://api2.transparencia.rr.gov.br/transparencia/oauth/token?grant_type=client_credentials");
    localStorage.setItem("p_url_api","https://api2.transparencia.rr.gov.br");      
    localStorage.setItem("p_url_uni_orc","https://api2.transparencia.rr.gov.br/transparencia/api/v1/unidades-orcamentarias");      
    this.generateToken();
    const lista_meses = [
        { value: '1', text: 'Janeiro' },
        { value: '2', text: 'Fevereiro' },
        { value: '3', text: 'Março' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Maio' },
        { value: '6', text: 'Junho' },
        { value: '7', text: 'Julho' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Setembro' },
        { value: '10', text: 'Outubro' },
        { value: '11', text: 'Novembro' },
        { value: '12', text: 'Dezembro' }];
    localStorage.setItem("p_lis_mes",JSON.stringify(lista_meses));
    const anoAtual = new Date().getFullYear();
    const lista_anos = [];
    for (let ano = anoAtual; ano >= 2007; ano--) { lista_anos.push({value: ano})}  
    localStorage.setItem("p_lis_ano",JSON.stringify(lista_anos));     
    const uni_orc_json = [ { "codigoUnidadeorcamentaria": "18606", "descricaoUnidadeOrcamentaria": " Fundo de Desenvolvimento Econômico e Social do Estado de Roraima - FUNDER", "sigla": "FUNDER " }, 
                           { "codigoUnidadeorcamentaria": "18605", "descricaoUnidadeOrcamentaria": " Fundo de Desenvolvimento Florestal do Estado de Roraima - FUNDEFER", "sigla": "FUNDEFER " }, 
                           { "codigoUnidadeorcamentaria": "35101", "descricaoUnidadeOrcamentaria": " Secretaria de Estado de Licitação e Contratação", "sigla": "SELC " }, 
                           { "codigoUnidadeorcamentaria": "19104", "descricaoUnidadeOrcamentaria": "Academia de Polícia Integrada", "sigla": "API" }, 
                           { "codigoUnidadeorcamentaria": "13301", "descricaoUnidadeOrcamentaria": "Agência Reguladora de Serviços Públicos Delegados do Estado de Roraima", "sigla": "ARESD/RR" }, 
                           { "codigoUnidadeorcamentaria": "18302", "descricaoUnidadeOrcamentaria": "Agência de Defesa Agropecuária do Estado de Roraima", "sigla": "ADERR" }, 
                           { "codigoUnidadeorcamentaria": "18502", "descricaoUnidadeOrcamentaria": "Agência de Desenvolvimento de Roraima - DESENVOLVE/RR", "sigla": "DESENVOLVE/RR " }, 
                           { "codigoUnidadeorcamentaria": "16401", "descricaoUnidadeOrcamentaria": "Agência de Fomento do Estado de Roraima", "sigla": "AFERR" }, 
                           { "codigoUnidadeorcamentaria": "18401", "descricaoUnidadeOrcamentaria": "Agência de Fomento do Estado de Roraima - DESENVOLVE/RR", "sigla": "DESENVOLVE/RR" }, 
                           { "codigoUnidadeorcamentaria": "1101", "descricaoUnidadeOrcamentaria": "Assembleia Legislativa do Estado de Roraima", "sigla": "ALE" }, 
                           { "codigoUnidadeorcamentaria": "13101", "descricaoUnidadeOrcamentaria": "Casa Civil", "sigla": "CASA CIVIL" }, 
                           { "codigoUnidadeorcamentaria": "13103", "descricaoUnidadeOrcamentaria": "Casa Militar", "sigla": "CASA MILITAR" }, 
                           { "codigoUnidadeorcamentaria": "13108", "descricaoUnidadeOrcamentaria": "Comissão Permanente de Licitação", "sigla": "CPL" }, 
                           { "codigoUnidadeorcamentaria": "21501", "descricaoUnidadeOrcamentaria": "Companhia Energética de Roraima", "sigla": "CERR" }, 
                           { "codigoUnidadeorcamentaria": "18501", "descricaoUnidadeOrcamentaria": "Companhia de Desenvolvimento de Roraima", "sigla": "CODESAIMA" }, 
                           { "codigoUnidadeorcamentaria": "20501", "descricaoUnidadeOrcamentaria": "Companhia de Águas e Esgotos de Roraima", "sigla": "CAER" }, 
                           { "codigoUnidadeorcamentaria": "16302", "descricaoUnidadeOrcamentaria": "Consórcio Interestadual de Desenvolvimento Sustentável da Amazônia Legal", "sigla": "Cons. Des Amaz Legal" }, 
                           { "codigoUnidadeorcamentaria": "13105", "descricaoUnidadeOrcamentaria": "Controladoria-Geral do Estado", "sigla": "CGE" }, 
                           { "codigoUnidadeorcamentaria": "19102", "descricaoUnidadeOrcamentaria": "Corpo de Bombeiros Militar do Estado de Roraima", "sigla": "CBMRR" }, 
                           { "codigoUnidadeorcamentaria": "32101", "descricaoUnidadeOrcamentaria": "Defensoria Pública do Estado de Roraima", "sigla": "DPE" }, 
                           { "codigoUnidadeorcamentaria": "19301", "descricaoUnidadeOrcamentaria": "Departamento Estadual de Trânsito de Roraima", "sigla": "DETRAN/RR" }, 
                           { "codigoUnidadeorcamentaria": "28301", "descricaoUnidadeOrcamentaria": "Departamento de Estradas de Rodagem do Estado de Roraima - DER/RR em Extinção", "sigla": "DER/RR em Extinção" }, 
                           { "codigoUnidadeorcamentaria": "13401", "descricaoUnidadeOrcamentaria": "Empresa Rádio e Televisão Difusora de Roraima", "sigla": "RADIORAIMA" }, 
                           { "codigoUnidadeorcamentaria": "18201", "descricaoUnidadeOrcamentaria": "Fundação Estadual do Meio Ambiente e Recursos Hídricos - FEMARH", "sigla": "FEMARH" }, 
                           { "codigoUnidadeorcamentaria": "16201", "descricaoUnidadeOrcamentaria": "Fundação Estadual do Meio Ambiente e Recursos Hídricos de Roraima", "sigla": "FEMARH-RR " }, 
                           { "codigoUnidadeorcamentaria": "1201", "descricaoUnidadeOrcamentaria": "Fundação Rio Branco de Educação, Rádio e Televisão", "sigla": "FUNDALEGIS" }, 
                           { "codigoUnidadeorcamentaria": "17202", "descricaoUnidadeOrcamentaria": "Fundação Universidade Virtual de Roraima", "sigla": "UNIVIRR" }, 
                           { "codigoUnidadeorcamentaria": "18202", "descricaoUnidadeOrcamentaria": "Fundação de Amparo a Pesquisa do Estado de Roraima", "sigla": "FAPERR" }, 
                           { "codigoUnidadeorcamentaria": "16202", "descricaoUnidadeOrcamentaria": "Fundação de Amparo a Pesquisa do Estado de Roraima - FAPER", "sigla": "FAPER" }, 
                           { "codigoUnidadeorcamentaria": "18601", "descricaoUnidadeOrcamentaria": "Fundo Especial da Assistência Técnica e Extensão Rural do Estado de Roraima", "sigla": "FUNDATER-RR" }, 
                           { "codigoUnidadeorcamentaria": "32601", "descricaoUnidadeOrcamentaria": "Fundo Especial da Defensoria Pública do Estado de Roraima", "sigla": "FUNDPE/RR" }, 
                           { "codigoUnidadeorcamentaria": "13601", "descricaoUnidadeOrcamentaria": "Fundo Especial da Procuradoria-Geral do Estado de Roraima", "sigla": "FUNDEPRO/RR" }, 
                           { "codigoUnidadeorcamentaria": "13602", "descricaoUnidadeOrcamentaria": "Fundo Especial da Procuradoria-Geral do Estado de Roraima", "sigla": ",FUNDEPRO/RR" }, 
                           { "codigoUnidadeorcamentaria": "25601", "descricaoUnidadeOrcamentaria": "Fundo Especial do Ministério Público do Estado de Roraima", "sigla": "FUEMP/RR" }, 
                           { "codigoUnidadeorcamentaria": "12601", "descricaoUnidadeOrcamentaria": "Fundo Especial do Poder Judiciário do Estado de Roraima", "sigla": "FUNDEJURR" }, 
                           { "codigoUnidadeorcamentaria": "1601", "descricaoUnidadeOrcamentaria": "Fundo Especial do Poder Legislativo", "sigla": "FUNESPLE" }, 
                           { "codigoUnidadeorcamentaria": "34601", "descricaoUnidadeOrcamentaria": "Fundo Estadual da Cultura", "sigla": "FUNCULTURA" }, 
                           { "codigoUnidadeorcamentaria": "23601", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Assistência Social", "sigla": "FEAS" }, 
                           { "codigoUnidadeorcamentaria": "16602", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Aval", "sigla": "FUNAVAL" }, 
                           { "codigoUnidadeorcamentaria": "18602", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Aval - FUNAVAL", "sigla": "FUNAVAL " }, 
                           { "codigoUnidadeorcamentaria": "26602", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Defesa do Consumidor", "sigla": "FUNDECON" }, 
                           { "codigoUnidadeorcamentaria": "21601", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Infra-Estrutura de Transportes", "sigla": "FEIT" }, 
                           { "codigoUnidadeorcamentaria": "20601", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Saúde", "sigla": "FUNSESAU" }, 
                           { "codigoUnidadeorcamentaria": "19604", "descricaoUnidadeOrcamentaria": "Fundo Estadual de Segurança Pública do Estado de Roraima - FESP/RR", "sigla": "FESP/RR" }, 
                           { "codigoUnidadeorcamentaria": "16604", "descricaoUnidadeOrcamentaria": "Fundo Estadual do Meio Ambiente", "sigla": "FEMA" }, 
                           { "codigoUnidadeorcamentaria": "18604", "descricaoUnidadeOrcamentaria": "Fundo Estadual do Meio Ambiente - FEMA", "sigla": "FEMA " }, 
                           { "codigoUnidadeorcamentaria": "23604", "descricaoUnidadeOrcamentaria": "Fundo Estadual do Trabalho - FET/RR", "sigla": "FET/RR" }, 
                           { "codigoUnidadeorcamentaria": "23605", "descricaoUnidadeOrcamentaria": "Fundo Estadual dos Direitos dos Idosos de Roraima", "sigla": "FEDDIR" }, 
                           { "codigoUnidadeorcamentaria": "23603", "descricaoUnidadeOrcamentaria": "Fundo Estadual para Pessoa com Deficiência", "sigla": "FEDEPE" }, 
                           { "codigoUnidadeorcamentaria": "23602", "descricaoUnidadeOrcamentaria": "Fundo Estadual para a Criança e o Adolescente", "sigla": "FECA" }, 
                           { "codigoUnidadeorcamentaria": "15601", "descricaoUnidadeOrcamentaria": "Fundo Financeiro do IPER", "sigla": "FUNFIPER" }, 
                           { "codigoUnidadeorcamentaria": "26601", "descricaoUnidadeOrcamentaria": "Fundo Penitenciário do Estado de Roraima", "sigla": "FUNPER" }, 
                           { "codigoUnidadeorcamentaria": "15602", "descricaoUnidadeOrcamentaria": "Fundo Previdenciário do IPER", "sigla": "FUNPREIPER" }, 
                           { "codigoUnidadeorcamentaria": "15603", "descricaoUnidadeOrcamentaria": "Fundo Previdenciário dos Militares Estaduais - FUNPREV/MILITAR ", "sigla": "FUNPREV/MILITAR" }, 
                           { "codigoUnidadeorcamentaria": "16601", "descricaoUnidadeOrcamentaria": "Fundo de Desenvolvimento Econômico e Social do Estado de Roraima", "sigla": "FUNDER" }, 
                           { "codigoUnidadeorcamentaria": "16605", "descricaoUnidadeOrcamentaria": "Fundo de Desenvolvimento Florestal do Estado de Roraima", "sigla": "FUNDEFER" }, 
                           { "codigoUnidadeorcamentaria": "16603", "descricaoUnidadeOrcamentaria": "Fundo de Desenvolvimento Industrial do Estado de Roraima", "sigla": "FDI" }, 
                           { "codigoUnidadeorcamentaria": "18603", "descricaoUnidadeOrcamentaria": "Fundo de Desenvolvimento Industrial do Estado de Roraima - FDI", "sigla": "FDI " }, 
                           { "codigoUnidadeorcamentaria": "17601", "descricaoUnidadeOrcamentaria": "Fundo de Manutenção e Desenvolvimento da Educação Básica e de Valorização dos Profissionais da Educação", "sigla": "FUNDEB" }, 
                           { "codigoUnidadeorcamentaria": "11601", "descricaoUnidadeOrcamentaria": "Fundo de Modernização do Tribunal de Contas do Estado de Roraima", "sigla": "FMTCE/RR" }, 
                           { "codigoUnidadeorcamentaria": "33601", "descricaoUnidadeOrcamentaria": "Fundo de Modernização e Aparelhamento do Ministério Público de Contas", "sigla": "FMMPC" }, 
                           { "codigoUnidadeorcamentaria": "22601", "descricaoUnidadeOrcamentaria": "Fundo de Modernização e Desenvolvimento Fazendário", "sigla": "FUNSEFAZ" }, 
                           { "codigoUnidadeorcamentaria": "19603", "descricaoUnidadeOrcamentaria": "Fundo de Modernização, Manutenção e Desenvolvimento da Polícia Civil - FUNDESPOL - RR ", "sigla": "FUNDESPOL - RR " }, 
                           { "codigoUnidadeorcamentaria": "19602", "descricaoUnidadeOrcamentaria": "Fundo de Reaparelhamento e Aperfeiçoamento da Polícia Militar", "sigla": "FREA/PM" }, 
                           { "codigoUnidadeorcamentaria": "19601", "descricaoUnidadeOrcamentaria": "Fundo de Reequipamento do Corpo de Bombeiros Militar de Roraima", "sigla": "FREBOM" }, 
                           { "codigoUnidadeorcamentaria": "16301", "descricaoUnidadeOrcamentaria": "Instituto de Amparo à Ciência, Tecnologia e Inovação do Estado de Roraima", "sigla": "IACTI-RR" }, 
                           { "codigoUnidadeorcamentaria": "18303", "descricaoUnidadeOrcamentaria": "Instituto de Assistência Técnica e Extensão Rural do Estado de Roraima - IATER", "sigla": "IATER-RR" }, 
                           { "codigoUnidadeorcamentaria": "17301", "descricaoUnidadeOrcamentaria": "Instituto de Educação de Roraima", "sigla": "IERR" }, 
                           { "codigoUnidadeorcamentaria": "22302", "descricaoUnidadeOrcamentaria": "Instituto de Pesos e Medidas do Estado de Roraima", "sigla": "IPEM/RR" }, 
                           { "codigoUnidadeorcamentaria": "15301", "descricaoUnidadeOrcamentaria": "Instituto de Previdência do Estado de Roraima", "sigla": "IPER" }, 
                           { "codigoUnidadeorcamentaria": "18301", "descricaoUnidadeOrcamentaria": "Instituto de Terras e Colonização do Estado de Roraima", "sigla": "ITERAIMA" }, 
                           { "codigoUnidadeorcamentaria": "22301", "descricaoUnidadeOrcamentaria": "Junta Comercial do Estado de Roraima", "sigla": "JUCERR" }, 
                           { "codigoUnidadeorcamentaria": "33101", "descricaoUnidadeOrcamentaria": "Ministério Público de Contas", "sigla": "MPC" }, 
                           { "codigoUnidadeorcamentaria": "25101", "descricaoUnidadeOrcamentaria": "Ministério Público do Estado de Roraima", "sigla": "MPE" }, 
                           { "codigoUnidadeorcamentaria": "22102", "descricaoUnidadeOrcamentaria": "Operações Especiais", "sigla": "OE" }, 
                           { "codigoUnidadeorcamentaria": "13106", "descricaoUnidadeOrcamentaria": "Ouvidoria-Geral do Estado de Roraima", "sigla": "OGE" }, 
                           { "codigoUnidadeorcamentaria": "19105", "descricaoUnidadeOrcamentaria": "Polícia Civil do Estado de Roraima", "sigla": "PCRR" }, 
                           { "codigoUnidadeorcamentaria": "19103", "descricaoUnidadeOrcamentaria": "Polícia Militar do Estado de Roraima", "sigla": "PMRR" }, 
                           { "codigoUnidadeorcamentaria": "13107", "descricaoUnidadeOrcamentaria": "Procuradoria-Geral do Estado de Roraima", "sigla": "PROGE" }, 
                           { "codigoUnidadeorcamentaria": "13110", "descricaoUnidadeOrcamentaria": "Relações Públicas e Cerimonial", "sigla": "CERIMONIAL" }, 
                           { "codigoUnidadeorcamentaria": "13109", "descricaoUnidadeOrcamentaria": "Representação do Governo", "sigla": "REPRESENTAÇÃO" }, 
                           { "codigoUnidadeorcamentaria": "39901", "descricaoUnidadeOrcamentaria": "Reserva de Contingência", "sigla": "RC" }, 
                           { "codigoUnidadeorcamentaria": "18101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Agricultura, Desenvolvimento e Inovação - SEADI ", "sigla": "SEADI" }, 
                           { "codigoUnidadeorcamentaria": "13104", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Comunicação Social", "sigla": "SECOM" }, 
                           { "codigoUnidadeorcamentaria": "34101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Cultura", "sigla": "SECULT" }, 
                           { "codigoUnidadeorcamentaria": "17101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Educação e Desporto", "sigla": "SEED" }, 
                           { "codigoUnidadeorcamentaria": "22101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Fazenda", "sigla": "SEFAZ" }, 
                           { "codigoUnidadeorcamentaria": "15101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Gestão Estratégica e Administração", "sigla": "SEGAD" }, 
                           { "codigoUnidadeorcamentaria": "21101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Infra-Estrutura", "sigla": "SEINF" }, 
                           { "codigoUnidadeorcamentaria": "26101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Justiça e Cidadania", "sigla": "SEJUC" }, 
                           { "codigoUnidadeorcamentaria": "20101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Saúde", "sigla": "SESAU" }, 
                           { "codigoUnidadeorcamentaria": "19101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado da Segurança Pública", "sigla": "SESP" }, 
                           { "codigoUnidadeorcamentaria": "30101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado das Cidades, Desenvolvimento Urbano e Gestão de Convênios - SECIDADES", "sigla": "SECIDADES" }, 
                           { "codigoUnidadeorcamentaria": "36101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado de Governo Digital", "sigla": "SEGOD" }, 
                           { "codigoUnidadeorcamentaria": "16101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado de Planejamento e Desenvolvimento", "sigla": "SEPLAN" }, 
                           { "codigoUnidadeorcamentaria": "13111", "descricaoUnidadeOrcamentaria": "Secretaria de Estado de Representação do Governo de Roraima em Brasília", "sigla": "SERBRAS" }, 
                           { "codigoUnidadeorcamentaria": "23101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado do Trabalho e Bem-Estar Social", "sigla": "SETRABES" }, 
                           { "codigoUnidadeorcamentaria": "27101", "descricaoUnidadeOrcamentaria": "Secretaria de Estado do Índio", "sigla": "SEI" }, 
                           { "codigoUnidadeorcamentaria": "99000", "descricaoUnidadeOrcamentaria": "Tesouro do Estado de Roraima", "sigla": "Tesouro RR" }, 
                           { "codigoUnidadeorcamentaria": "11101", "descricaoUnidadeOrcamentaria": "Tribunal de Contas do Estado de Roraima", "sigla": "TCE" }, 
                           { "codigoUnidadeorcamentaria": "12101", "descricaoUnidadeOrcamentaria": "Tribunal de Justiça do Estado de Roraima", "sigla": "TJ/RR" },
                           { "codigoUnidadeorcamentaria": "17201", "descricaoUnidadeOrcamentaria": "Universidade Estadual de Roraima", "sigla": "UERR" }, 
                           { "codigoUnidadeorcamentaria": "17102", "descricaoUnidadeOrcamentaria": "Universidade Virtual de Roraima - UNIVIRR", "sigla": ",UNIVIRR" }, 
                           { "codigoUnidadeorcamentaria": "13102", "descricaoUnidadeOrcamentaria": "Vice Governadoria", "sigla": "VICE" } ];
    localStorage.setItem("p_lis_uni_orc",JSON.stringify(uni_orc_json));                      
  },
  methods: {
    // *********************************************** GERA TOKEN ********************
    async generateToken() {
      const credentials = {
        username: this.username_api,
        password: this.password_api
      };
      const url_api_token = localStorage.getItem("p_url_api_token");
      const url_api = localStorage.getItem("p_url_api");
      try {
        const response = await axios.post(url_api_token,null,{auth :credentials},);
        this.token = response.data["access_token"]; 
        localStorage.setItem('p_token_api', this.token);
        this.carregaUniOrc();
      } catch (error) {
          console.log('erro ao acessar '+url_api);
//        Swal.fire({confirmButtonColor: '#3085d6',title: 'Erro ao gerar token',
//            html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Infelizmente não temos acesso aos dados da API hospedada na</p> <p>URL '+url_api+'</p> <p>'+error.message+'</p>',
//            icon: '',confirmButtonText: 'Ok'});
           
      }
    },

    //***************************************CARREGA UNIDADES ORCAMENTARIAS *************/
    async carregaUniOrc() {
      const url_uni_orc = localStorage.getItem("p_url_uni_orc");
      const token_api =  localStorage.getItem("p_token_api");
      try {
        const response = await axios.get(url_uni_orc,{headers: {Authorization: `Bearer ${token_api}`}},);
        const lis_uni_orc = response.data; // 
        localStorage.setItem("p_lis_uni_orc",JSON.stringify(lis_uni_orc));   //   GUARDA LISTA DE UNIDADES ORCAMENTARIAS EM UMA STORAGE
      } catch (error) {
         console.log('erro ao acessar '+url_uni_orc);
//        Swal.fire({
//            confirmButtonColor: '#3085d6',
//            title: 'Erro ao carregar unidades orçamentárias',
//            html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Infelizmente não temos acesso aos dados da API hospedada na</p> <p>URL '+url_uni_orc+'</p> <p>'+error.message+'</p>',
//            icon: '',confirmButtonText: 'Ok'});
      }
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
