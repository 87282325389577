<template>
    <div class="rodape">
        <div>
          <img src="@/assets/cge-rr.png" style="padding: 10px"/>
        </div>
        <div style="padding: 10px">
          <h2>CGE/RR</h2>
          <h4>Departamento de Transparência e Controle Social</h4>
          <h4>Núcleo de Informática - v1.0.241015</h4>          
        </div>
        <div>
          <img src="@/assets/logo_governo_rr.png"  style="padding: 10px"/>        
        </div>
    </div>
  </template>
  
<script>
    export default {};
</script>
  
<style scoped>
    .rodape {
      background-image: linear-gradient(to right, #52a2dc, #4d9ad5 18%, #4085c3 45%, #2b64a5 77%, #1a488d);
      color: white;
      text-align: center;
      position: relative;
      bottom: 0;
      width: 100%;
      margin: 5px auto; /* margem inferior */
      border-radius: 10px; /* Aqui você define o arredondamento */    
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;}
</style>
  